
import { Action, Getter } from "vuex-class";
import { Component, Ref, Mixins } from "vue-property-decorator";
import { FormBase, FormInput, FormError, FormSubmit, FormSelect, FormPhone } from "@/components/forms";
import { HasFormErrors } from "@/mixins/has-form-errors";
import { CreatePartnerPayload } from "@/store/modules/partner.store";
import { PartnerType } from "@/store/modules/partner.store";
import { Partner } from "@/store/modules/partner.store";
import { Office } from "@/store/modules/office.store";

@Component({
    components: {
        FormBase,
        FormInput,
        FormError,
        FormSelect,
        FormPhone,
        FormSubmit,
    },
})
export default class PageAdminUsersCreate extends Mixins(HasFormErrors) {
    @Getter("office/all") offices!: Office[];

    @Action("partner/create") createPartner!: (payload: CreatePartnerPayload) => Partner;

    @Ref() form!: FormClass;

    payload: CreatePartnerPayload = {
        company_name: "",
        type: PartnerType.photo,
        office_ids: [],
        services: [
            {
                title: "",
                addons: [{ title: "" }],
            },
        ],
        accounts: [
            {
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
            },
        ],
    };

    get officeOptions() {
        return this.offices.map((o) => {
            return {
                label: o.name,
                value: o.id,
            };
        });
    }

    get partnerTypeOptions() {
        return Object.values(PartnerType).map((type: PartnerType) => ({ value: type, label: this.$t(`views.partner.option_name.${type}_title`) }));
    }

    async submit(form: FormClass) {
        try {
            // clear empty services
            this.payload.services = this.payload.services!.filter((service) => service.title !== "");

            // clear empty addons
            this.payload.services.forEach((service) => {
                if (service.addons) {
                    service.addons = service.addons!.filter((addon) => addon.title !== "");
                }
            });

            await this.createPartner(this.payload);

            this.$toast.open({ message: "Partner uitgenodigd", type: "success" });

            this.$router.push({ name: "admin-partners" });
        } catch (e: any) {
            this.errorResponse = this.formatErrors(e);
        } finally {
            form.reset();
        }
    }

    addService() {
        if (!this.payload.services) {
            this.payload.services = [];
        }

        this.payload.services.push({
            title: "",
            addons: [{ title: "" }],
        });
    }

    removeService(index: number) {
        if (!this.payload.services) {
            return;
        }

        this.payload.services.splice(index, 1);
    }

    addAddon(index: number) {
        if (!this.payload.services) {
            return;
        }

        if (!this.payload.services[index].addons) {
            this.payload.services[index].addons = [];
        }

        this.payload.services[index].addons!.push({
            title: "",
        });
    }

    removeAddon(service_index: number, addon_index: number) {
        if (!this.payload.services) {
            return;
        }

        if (!this.payload.services[service_index].addons) {
            return;
        }

        this.payload.services[service_index].addons!.splice(addon_index, 1);
    }
}
